<template>
  <div class="sticky z-20 top-0 left-0 w-full bg-sky flex justify-center">
    <ClientOnly>
      <a class="flex items-center gap-2 px-2 py-1 opacity-60 hover:opacity-100 transition-all" :href="url">
        <AtomIcon name="circle-info" class="text-sm" />
        <div class="text-xs">{{ $t('fat.banners.classic') }}</div>
      </a>
    </ClientOnly>
  </div>
</template>

<script async setup>
const fat = useFatStore()
const url = computed(() => `https://bokabord.se/restauranger?antal=${fat.amount}&datum=${fat.date}&orter=${fat.cityId}&typ=${fat.meal}`)
</script>