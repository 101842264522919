<template>
  <div class="sticky z-20 top-0 left-0 w-full bg-ocean text-white flex justify-center" v-if="dateHasExpired">
    <div class="inline-flex items-center gap-2 px-2 py-1 cursor-pointer" @click="updateFatDate">
      <AtomIcon name="circle-info" class="text-sm" />
      <div class="text-xs">{{ $t('fat.banners.oldDate', { date: $dayjs(fat.date).format('dddd D MMMM')}) }}</div>
    </div>
  </div>
</template>

<script async setup>
const dayjs = useDayjs()
const fat = useFatStore()
const dateHasExpired = computed(() => dayjs(fat.date).isBefore(dayjs(), 'day'))

const updateFatDate = () => {
  fat.date = dayjs().format('YYYY-MM-DD')
}
</script>