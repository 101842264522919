<template>
  <div class="flex justify-between items-center">
    <div class="font-medium text-sm">{{ $t('fat.geo.distance') }}</div>
    <div class="flex gap-0.5 items-center" v-auto-animate>
      <div v-for="option in options" :key="option.distance" class="text-sm flex-1">
        <div class="pill" :class="{ 'active': option.active }" @click="setDistance(option.distance)">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const fat = useFatStore()

// Set Distance and trigger fetch
const setDistance = (value) => {
  fat.setDistance(value)
  fat.pushQuery()
}

// Updated computed property with trimming logic
const options = computed(() => {
  // First, mark the option as active
  const updatedOptions = distanceOptions.map(option => ({
    ...option,
    active: option.distance === parseInt(fat.distance)
  }))

  // Then, trim the array to ensure the active option is centered
  return trimOptionsArray(updatedOptions, 5)
})

// Helper function to trim the options array to five items with the active item centered
const trimOptionsArray = (options, itemsCount) => {
  const activeIndex = options.findIndex(option => option.active)
  let startIndex = 0
  const itemsBefore = Math.floor(itemsCount / 2)
  const itemCenter = Math.ceil(itemsCount / 2)

  if (activeIndex >= itemsBefore && activeIndex <= options.length - itemCenter) {
    startIndex = activeIndex - itemsBefore
  } else if (activeIndex > options.length - itemCenter) {
    startIndex = Math.max(0, options.length - itemsCount)
  }

  // Ensures that the array is sliced to have a maximum of five items
  return options.slice(startIndex, startIndex + itemsCount)
}

// Options
const distanceOptions = [
  // {
  //   label: '200m',
  //   distance: 200
  // },    
  // {
  //   label: '500m',
  //   distance: 500
  // },  
  {
    label: '1km',
    distance: 1000
  },  
  // {
  //   label: '2km',
  //   distance: 2000
  // },  
  {
    label: '3km',
    distance: 3000
  },  
  {
    label: '5km',
    distance: 5000
  }, 
  // {
  //   label: '7.5km',
  //   distance: 7500
  // },  
  {
    label: '10km',
    distance: 10000
  },  
  // {
  //   label: '15km',
  //   distance: 15000
  // },  
  // {
  //   label: '20km',
  //   distance: 20000
  // },  
  // {
  //   label: '30km',
  //   distance: 30000
  // },  
  // {
  //   label: '50km',
  //   distance: 50000
  // }
]
</script>

<style lang="scss" scoped>
.position {
  @include boxer();
}

.pill {
  @apply text-center cursor-pointer transition-all;
  @apply bg-mist rounded-sm px-2 py-0.5;

  &:hover {
    @apply bg-sand/50;
  }

  &.active {
    @apply bg-coral text-white;
  }

  &.disabled {
    @apply pointer-events-none cursor-default text-black/20;
  }
}
</style>