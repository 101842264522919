<template>
  <div class="">
    <div class="flex items-center gap-2 cursor-pointer" @click="ui.fatLocation = !ui.fatLocation">
      <AtomIcon name="location-dot" class="text-lg text-coral" />
      <div class="text-sm">
        {{ fat.location ? $t('fat.nearMe') : (city?.name || $t('fat.selectCity')) }}
      </div>
    </div>
    <FatLocationModal>
      <div class="p-4 sticky z-30 top-0 bg-white">
        <div class="flex justify-between">
          <div>
            <DialogTitle as="h2" class="font-bold text-2xl">{{ $t('fat.location.heading') }}</DialogTitle>
            <DialogDescription class="hidden"></DialogDescription>
          </div>
          <div class="flex items-center gap-2 text-sm cursor-pointer" @click="fatInit">
            <div>{{ $t('shared.close') }}</div>
            <AtomIcon name="circle-xmark" />
          </div>
        </div>
      </div>
      <div class="px-4 pt-2 pb-24 sm:pb-4 overflow-y-auto">
        <FatLocationGeo class="mb-6" />
        <FatLocationPopularCities class="mb-6" />
        <FatLocationCities />
      </div>
      <div class="p-4 grid gap-4 sticky z-30 bottom-0 bg-white">
        <button class="button relative text-sm w-full" @click="fatInit" :class="{ 'pointer-events-none': fat.loading }" data-style="coral">
          <AtomSpinner class="w-4 text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" :class="{ 'opacity-100': fat.loading }" />
          <span :class="{ 'opacity-0': fat.loading }">
            {{ $t('fat.button', { count: route.name === 'restaurants' ? total : '' }) }}
          </span>
        </button>
      </div>
    </FatLocationModal>
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()
const ui = useUiStore()
const fat = useFatStore()

// Navigate to search result
const fatInit = () => {
  ui.fatLocation = false
  fat.pushQuery()
  
  if (route.name !== 'restaurants') {
    setTimeout(() => {
      router.push({ name: 'restaurants', query: { ...route.query }})
    }, 100)
  }
}

// Get cities via Nuxt Data
const { data: dataCities } = useNuxtData('cities')
const cities = dataCities.value?.data

// Find city object based on city ID
const city = computed(() => cities?.find(city => city.id === fat.cityId))

// Use FAT result data
const { data: fatResult } = useNuxtData('fat-result')
const total = computed(() => fatResult.value?.data?.total)
</script>