
//
// Get geo location for user
//
export function useGetGeoLocation (callback) {
  // console.log('Get Position from User')
  // console.log('navigator.geolocation', !!navigator.geolocation)
  
  if ('geolocation' in navigator) {
    navigator.geolocation.getCurrentPosition(
      position => {
        const latitude = position.coords.latitude
        const longitude = position.coords.longitude
        // console.log('success', latitude, longitude)

        callback({
          type: 'success',
          location: {
            lat: latitude,
            lon: longitude
          }
        })
      },
      error => {
        console.error('Error getting Position from User:', error.message)
        
        callback({
          type: 'error',
          message: error.message
        })
      }
    )
  } else {
    console.log('Geolocation is not available')
    
    callback({
      type: 'error',
      message: 'Geolocation is not available'
    })
  }
}
