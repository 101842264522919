<template>
  <div class="cursor-pointer">
    <AtomSwitch :active="active" :loading="status === 'loading'" @click="toggleLocation()" />
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()
const fat = useFatStore()
const user = useUserStore()
const status = ref('')

// Check active state for Switch
const active = computed(() => status.value === 'loading' || !!(user.allowLocation && route.query.location))

// Toggle location 
const toggleLocation = () => {
  if (!user.allowLocation || !route.query.location) {
    getLocation()
  } else {
    user.allowLocation = null
    user.location = { lat: null, lon: null }
    fat.coordinates = null
    fat.pushQuery()
  }
}

// Get location and update user store
const getLocation = () => {
  status.value = 'loading'
  user.allowLocation = null

  useGetGeoLocation((response) => {
    if (response.type === 'success') {
      status.value = 'success'
      user.allowLocation = true
      
      // Update user and fat stores
      user.location = response.location
      fat.cityId = null
      fat.coordinates = user.coordinates
      
      router.push({ 
        name: 'restaurants',
        query: {
          ...route.query,
          paged: undefined,
          cityId: undefined,
          location: `${user.coordinates},${fat.distance}` 
        }
      })
    } else {
      console.error(response.message)
      status.value = 'error'
      user.allowLocation = false
    }
  })
}
</script>

<style lang="scss" scoped>
// 
</style>