import { acceptHMRUpdate, defineStore } from 'pinia'

export const useUserStore = defineStore('user', {
  persist: true,

  state: () => ({
    email: null,
    phone: null,
    firstName: null,
    lastName: null,
    allowLocation: null,
    location: {
      lat: null,
      lon: null
    }
  }),

  getters: {
    coordinates() {
      return (!!this.location?.lat && !!this.location?.lon)
        ? `${this.location.lat},${this.location.lon}`
        : null
    }
  },

  actions: {
    reset() {
      this.$reset()
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
}
