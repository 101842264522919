<template>
  <div class="sticky z-30 top-0 left-0 w-full px-4 py-2 bg-white border-b">
    <div class="flex items-center justify-between w-full max-w-6xl mx-auto">
      <div class="flex items-center gap-8">
        <NuxtLink :to="{ name: 'start' }" class="flex items-end gap-2">
          <AtomIcon name="bb-logo-name" class="text-[1.5rem] sm:text-[1.75rem] text-coral" />
          <div class="text-[0.625rem] leading-none py-1 font-bold bg-sky text-ocean rounded-sm px-[0.375rem] -mb-px">BETA</div>
        </NuxtLink>
        <div class="text-xs md:text-sm">
          <!-- <div class="flex items-center gap-6 hidden sm:flex">
            <NuxtLink :to="{ name: 'restaurants', query: { ...route.query }}">{{ $t('shared.nav.restaurants') }}</NuxtLink>
            <NuxtLink :to="{ name: 'events', query: { ...route.query }}">{{ $t('shared.nav.events') }}</NuxtLink>
            <NuxtLink :to="{ name: 'offers', query: { ...route.query }}">{{ $t('shared.nav.offers') }}</NuxtLink>
            <NuxtLink :to="{ name: 'stockholm-food-weeks', query: { ...route.query }}">Stockholm Food Weeks</NuxtLink>
          </div> -->
        </div>
      </div>
      <div class="flex items-center gap-4 sm:gap-6">
        <!-- <AppPlace /> -->
        <!-- <UserPosition /> -->
        <!-- <FatSearchCityId /> -->
        <FatLocation />
        <button class="button !py-2 relative text-xs sm:text-sm" @click="fatSearch()" :class="{ 'pointer-events-none': fat.loading }" data-style="coral">
          <AtomSpinner class="w-4 text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0" :class="{ 'opacity-100': fat.loading }" />
          <span :class="{ 'opacity-0': fat.loading }">Sök bord</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script async setup>
const route = useRoute()
const router = useRouter()
const ui = useUiStore()
const fat = useFatStore()

// Push route and show search settings
const fatSearch = () => {
  ui.fatSearch = true
  router.push({ name: 'restaurants' })
}
</script>