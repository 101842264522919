<template>
  <div class="" v-auto-animate>
    <div class="flex items-center justify-between mb-2">
      <p class="text-lg font-bold">{{ $t('fat.location.headingAll') }}</p>
      <div class="flex items-center gap-2">
        <div class="cursor-pointer transition-all" @click="searchField = !searchField" :class="{ 'opacity-50': !searchField }">
          <AtomIcon name="magnifying-glass" class="h-6 w-6 text-sm" />
        </div>
        <div class="flex items-center rounded-full border overflow-hidden">
          <div class="px-3 flex items-center h-full cursor-pointer transition-all border-r" :class="{ 'bg-mist': sortBy === 'name', 'opacity-50': sortBy !== 'name' }" @click="setSort('name')">
            <AtomIcon name="arrow-down-a-z" class="h-6 text-sm" v-if="orderBy === 'desc'" />
            <AtomIcon name="arrow-up-a-z" class="h-6 text-sm" v-else />
          </div>
          <div class="px-3 flex items-center h-full cursor-pointer transition-all border-r" :class="{ 'bg-mist': sortBy === 'count', 'opacity-50': sortBy !== 'count' }" @click="setSort('count')">
            <AtomIcon name="arrow-down-wide-short" class="h-6 text-sm" v-if="orderBy === 'desc'" />
            <AtomIcon name="arrow-up-wide-short" class="h-6 text-sm" v-else />
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2 relative" v-if="searchField">
      <input type="text" v-model="searchPhrase" :placeholder="$t('fat.location.searchField')" class="w-full px-2 py-1 border rounded-sm" />
      <div class="absolute right-1 top-1/2 -translate-y-1/2">
        <AtomIcon name="circle-xmark" class="h-6 w-6 text-sm cursor-pointer" @click="searchPhrase = ''" v-if="searchPhrase !== ''" />
      </div>
    </div>
    <div class="grid gap-1" v-auto-animate>
      <div v-for="city in sortedCities" :key="city.id" class="bg-mist rounded-md transition-all hover:bg-coral hover:text-white" :class="{ '!bg-coral !text-white': fat.cityId === city.id }">
        <div class="flex justify-between cursor-pointer py-2 px-2" @click="onClick(city.id)">
          <div class="font-medium text-sm">
            {{ city.name }}
          </div>
          <div class="font-medium text-sm">
            {{ city.restaurants }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const ui = useUiStore()
const fat = useFatStore()
const route = useRoute()
const router = useRouter()
const searchField = ref(false)
const searchPhrase = ref('')
const sortBy = ref('name')
const orderBy = ref('desc')

// Get cities via Nuxt Data
const { data } = useNuxtData('cities')
const cities = data.value?.data?.filter(city => city.restaurants)

// Filter and sort the cities
const sortedCities = computed(() => {
  let result = [...cities]

  // Filter by search phrase if provided
  if (searchPhrase.value) {
    const searchPhraseLowerCase = searchPhrase.value.toLowerCase()
    result = result.filter(city => city.name.toLowerCase().includes(searchPhraseLowerCase))
  }

  // Sort the array based on restaurants
  if (sortBy.value === 'count') {
    result.sort((a, b) => b.restaurants - a.restaurants)
  }

  // Reverse the order
  if (orderBy.value === 'asc') {
    result.reverse()
  }

  return result
})

// Set sort attribute, or switch order
const setSort = (value) => {
  if (sortBy.value === value) {
    orderBy.value === 'desc'
      ? orderBy.value = 'asc'
      : orderBy.value = 'desc'
  } else {
    sortBy.value = value
  }
}

// Update city ID on click
const onClick = (cityId) => {
  fat.cityId = cityId
  fat.coordinates = null
  fat.pushQuery()
  ui.fatLocation = false

  // if (route.name !== 'restaurants') {
  //   setTimeout(() => {
  //     router.push({ name: 'restaurants', query: { ...route.query }})
  //   }, 100)
  // }
}
</script>

<style lang="scss" scoped>
.position {
  @include boxer();
}
</style>