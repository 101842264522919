<template>
  <div class="">
    <p class="mb-2 text-lg font-bold">{{ $t('fat.location.headingPopular') }}</p>
    <div class="grid grid-cols-3 gap-2">
      <div v-for="city in sortedCities.slice(0, 3)" :key="city.id">
        <div class="rounded-md bg-mist text-center overflow-hidden transition-all hover:bg-coral hover:text-white group" :class="{ '!bg-coral !text-white': fat.cityId === city.id }">
          <div class="relative flex flex-col justify-center cursor-pointer h-32 py-3" @click="onClick(city.id)">
            <div class="relative z-20">
              <div class="text-2xl my-2 text-coral transition-all group-hover:text-white" :class="{ '!text-white': fat.cityId === city.id }">
                <AtomIcon name="location-dot" />
              </div>
              <div class="font-medium text-lg">
                {{ city.restaurants }}
              </div>
              <div class="font-medium text-sm">
                {{ city.name }}
              </div>
            </div>
            <!-- <div class="absolute z-10 top-0 left-0 w-full h-full">
              <AtomMap class="opacity-20" zoom="8" :lat="city?.location?.lat" :lon="city?.location?.lon" />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const ui = useUiStore()
const fat = useFatStore()
const route = useRoute()
const router = useRouter()

// Update city ID on click
const onClick = (cityId) => {
  fat.cityId = cityId
  fat.coordinates = null
  fat.pushQuery()
  ui.fatLocation = false

  // if (route.name !== 'restaurants') {
  //   setTimeout(() => {
  //     router.push({ name: 'restaurants', query: { ...route.query }})
  //   }, 100)
  // }
}

// Get cities via Nuxt Data
const { data } = useNuxtData('cities')
const cities = data.value?.data

// Sort by resturants 
const sortedCities = [...cities]
sortedCities.sort((a, b) => b.restaurants - a.restaurants)
</script>

<style lang="scss" scoped>
.position {
  @include boxer();
}
</style>