<template>
  <div class="bg-mist rounded-md overflow-hidden" v-auto-animate>
    <div class="h-32 w-full bg-sand text-coral" v-if="user.coordinates">
      <AtomMap :lat="user.location?.lat" :lon="user.location?.lon" :zoom="zoom" />
    </div>
    <div class="p-4">
      <div class="flex items-center justify-between">
        <div class="">
          <p class="font-bold text-lg">{{ $t('fat.geo.heading') }}</p>
          <p class="text-sm">{{ $t('fat.geo.leading') }}</p>
        </div>
        <div class="flex items-center">
          <FatLocationGeoPosition />
        </div>
      </div>
      <div class="pt-3 mt-3 border-t" v-if="fat.coordinates">
        <FatLocationGeoDistance />
      </div>
      <div class="mt-2" v-if="user.allowLocation === false">
        <div class="text-red-500 text-xs">{{ $t('fat.geo.error') }}</div>
      </div>
    </div>
  </div> 
</template>

<script setup>
const fat = useFatStore()
const user = useUserStore()

const zoom = computed(() => {
  switch (fat.distance) {
    case 1000:
      return 15
    case 3000:
      return 14
    case 5000:
      return 13
    case 10000:
      return 12
    default:
      return 12
    }
})
</script>